$bp-medium: 56.25em; // 900px
$bp-small: 37.5em; // 600px

$color-text: #0d1744;

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 95;
  background: rgba(0, 0, 0, 0.7);
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 71rem;
  z-index: 99;
  overflow: hidden;
  background-color: #fff;
  border-radius: 0.8rem;
  box-shadow: 0px 1px 30px rgba(58, 70, 83, 0.06);
  padding: 4rem;

  .close {
    position: absolute;
    right: 2.6rem;
    top: 2.6rem;
    width: 1.7rem;
    height: 1.7rem;
    background: url('./assets/close.svg') no-repeat center;
    background-size: cover;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }

  nav[class*='nav-tabs'] {
    margin-bottom: 3rem;
    border-bottom: 1px solid transparent;

    & > a {
      font-size: 4rem;
      line-height: 6rem;
      font-weight: 600;
      border-color: transparent !important;
      cursor: pointer;
      color: rgba($color-text, 0.3);
      padding: 0;

      &:not(:last-child) {
        margin-right: 4rem;
      }
    }

    & > a[class*='active'] {
      color: $color-text;
    }
  }

  .itemsSmall {
    display: none;
  }
}

@media only screen and (max-width: $bp-medium) {
  .modal {
    position: relative;
    top: 50%;
    margin-bottom: 14rem;

    .close {
      width: 1.1rem;
      height: 1.1rem;
    }

    nav[class*='nav-tabs'] {
      margin-bottom: 2rem;

      & > a {
        font-size: 2.6rem;
        line-height: 3.1rem;

        &:not(:last-child) {
          margin-right: 3rem;
        }
      }
    }

    .itemsSmall {
      display: block;
      height: 60vh;
      overflow-x: hidden;
      overflow-y: auto;

      .divis {
        &:not(:last-child) {
          margin-bottom: 3.2rem;
        }
      }

      .work {
        &:not(:last-child) {
          margin-bottom: 1.8rem;
        }
      }
    }
  }
}

@media only screen and (max-width: $bp-small) {
  .modal {
    width: 100%;
  }
}
