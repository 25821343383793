$bp-medium: 56.25em; // 900px
$bp-smallest: 31.25em; // 500px

.sponsors {
  margin-top: 10rem;
  margin-bottom: 10rem;
  text-align: center;

  h2 {
    font-weight: 600;
    font-size: 4rem;
    line-height: 6rem;
    margin-bottom: 2rem;
  }

  p {
    max-width: 52.8rem;
    display: inline-block;
    line-height: 2.4rem;
    margin-bottom: 4rem;
  }

  .logos {
    .row {
      max-width: 104rem;
      margin: 0 auto 2rem;
      height: 6.9rem;
      display: flex;
      align-items: center;
      justify-content: space-around;

      &:last-child {
        margin: 0 auto;
      }

      .world {
        width: 13.86rem;
        height: 5.2rem;
        background: url('./assets/1st-phorm.png') no-repeat center;
        background-size: cover;
      }

      .ufc {
        width: 12.35rem;
        height: 8.2rem;
        background: url('./assets/romwod.png') no-repeat center;
        background-size: contain;
      }

      .golds {
        width: 18rem;
        height: 5.3rem;
        background: url('./assets/rxsg.png') no-repeat center;
        background-size: contain;
      }

      .crunch {
        width: 9.694rem;
        height: 6.1rem;
        background: url('./assets/yeti.png') no-repeat center;
        background-size: contain;
      }

      .cc {
        width: 8.08rem;
        height: 6.9rem;
        background: url('./assets/nobull.png') no-repeat center;
        background-size: contain;
      }
    }
  }
}

@media only screen and (max-width: $bp-medium) {
  .sponsors {
    margin-top: 3.8rem;
    margin-bottom: 6rem;

    h2 {
      font-size: 2.6rem;
      margin-bottom: 1.6rem;
      line-height: 2.9rem;
    }

    .logos {
      .row {
        min-height: 2.874rem;
        justify-content: space-evenly;
        flex-wrap: wrap;
        margin: 0 auto 3.2rem;

        a {
          width: 50%;
          margin: 0 0 1.2rem 0;
          // display: block;

          div {
            margin: auto;
          }
        }

        .hide {
          display: none;
        }

        .world {
          background-size: contain;
          width: 20.424rem;
          height: 6.41rem;
        }

        .ufc {
          width: 12.799rem;
          height: 4.674rem;
        }

        .golds {
          width: 8.343rem;
          height: 3.605rem;
        }

        .crunch {
          width: 8.493rem;
          height: 4.827rem;
        }

        .cc {
          margin-top: 2rem;
          width: 8.08rem;
          height: 4.9rem;
        }
      }
    }
  }
}

@media only screen and (max-width: $bp-smallest) {
  .sponsors {
    p {
      max-width: 29.1rem;
    }

    .logos {
      .row {
        justify-content: space-between;
      }
    }
  }
}
