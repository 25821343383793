$bp-medium: 56.25em; // 900px
$bp-small: 37.5em; // 600px
$bp-smallest: 31.25em; // 500px

.logo {
  width: 15rem;
  height: 5.7rem;
  background: url(./assets/linchpin-logo.png) no-repeat center;
  background-size: contain;
  flex-shrink: 0;

  &.btwb {
    width: 12.8rem;
    background: url(./assets/logo.svg) no-repeat left;
    background-size: contain;
  }
}

@media only screen and (max-width: $bp-medium) {
  .logo {
    width: 12rem;
    height: 4rem;

    &.btwb {
      width: 7.5rem;
      height: 2rem;
    }
  }
}
