$bp-medium: 56.25em; // 900px
$bp-smallest: 31.25em; // 500px

$primary-color: #4554d6;
$color-bg: #fbfcff;
$notification-bg: #edf2ff;
$color-separator: #edf2ff;
$color-text: #0d1744;

.success {
  position: fixed;
  z-index: 90;
  top: 0;
  bottom: 0;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #fff;
  display: flex;

  .source {
    width: 100%;
    display: flex;
    justify-content: center;

    .app,
    .google {
      position: relative;
      border-radius: 0.9rem;
      background-color: #000;
      border-color: #a6a6a6;
      height: 5.1rem;

      &:hover {
        background-color: #343a40;
        border-color: #a6a6a6;
      }

      .icon {
        position: absolute;
      }
    }

    .app {
      margin-right: 1.6rem;
      width: 15.3rem;

      .icon {
        width: 2.259rem;
        height: 2.776rem;
        top: 50%;
        left: 1.2rem;
        transform: translateY(-50%);
        background: url('./assets/apple.svg') no-repeat center;
        background-size: cover;
      }

      .subtitle {
        position: absolute;
        top: 1.073rem;
        left: 4.546rem;
        width: 8.817rem;
        height: 0.812rem;
        background: url('./assets/download.svg') no-repeat center;
        background-size: cover;
      }

      .title {
        position: absolute;
        top: 2.266rem;
        left: 4.393rem;
        width: 9.572rem;
        height: 2.004rem;
        background: url('./assets/app-store.svg') no-repeat center;
        background-size: cover;
      }
    }

    .google {
      width: 17.2rem;

      .icon {
        width: 2.942rem;
        height: 3.279rem;
        top: 50%;
        left: 1.2rem;
        transform: translateY(-50%);
        background: url('./assets/play.svg') no-repeat center;
        background-size: cover;
      }

      .subtitle {
        position: absolute;
        top: 0.875rem;
        left: 5.269rem;
        width: 4.95rem;
        height: 0.799rem;
        background: url('./assets/get.svg') no-repeat center;
        background-size: cover;
      }

      .title {
        position: absolute;
        top: 2.173rem;
        left: 5.229rem;
        width: 10.801rem;
        height: 2.171rem;
        background: url('./assets/google-play.svg') no-repeat center;
        background-size: cover;
      }
    }
  }

  .left {
    position: relative;
    flex-grow: 1;
    background-color: $color-bg;
    max-width: 61.7rem;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .logo {
      position: absolute;
      top: 2rem;
      left: 50%;
      transform: translateX(-50%);
    }

    h2 {
      display: none;
    }

    .grace {
      max-width: 44.6rem;
      height: 44.6rem;
      border-radius: 0.8rem;
      background-color: $primary-color;
      padding: 4rem 4rem 3.7rem;
      display: flex;
      flex-direction: column;
      margin-bottom: 2.4rem;

      h1,
      h2 {
        font-family: 'Oswald', sans-serif;
        font-weight: 600;
        text-transform: uppercase;
      }

      h1 {
        font-size: 7.8rem;
        line-height: 10rem;
        color: #fff;
        margin-bottom: 0.8rem;
      }

      h2 {
        display: block;
        font-size: 4.8rem;
        line-height: 6.4rem;
        color: $color-text;
        margin-bottom: auto;
      }

      .labels {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .label {
          font-family: 'Oswald', sans-serif;
          font-weight: 600;
          font-size: 2.4rem;
          line-height: 3.6rem;
          color: #fff;
          text-transform: uppercase;
        }

        .label-icon {
          width: 11.563rem;
          height: 3rem;
          background: url('./assets/btwb.svg') no-repeat center;
          background-size: cover;
        }
      }
    }

    .share {
      font-family: 'Gilroy', sans-serif;
      font-weight: 600;
      font-size: 1.6rem;
      line-height: 1.9rem;
      height: 5.5rem;
      border-radius: 0.8rem;
      background: $primary-color;
      border-color: $primary-color;
      max-width: 44.6rem;
      margin-bottom: 4rem;

      &:hover {
        background-color: #0069d9;
        border-color: #0062cc;
      }

      &:focus {
        outline: none;
      }
    }
  }

  .right {
    flex-grow: 1;

    display: flex;
    align-items: center;

    h2 {
      font-size: 3.4rem;
      line-height: 4.1rem;
      font-weight: 600;
      margin-bottom: 3rem;
    }

    h3 {
      font-weight: 600;
      font-size: 1.6rem;
      line-height: 3.6rem;
      text-transform: uppercase;
      letter-spacing: 0.05rem;
      opacity: 0.5;
      margin-bottom: 2px;
    }

    .info {
      margin-bottom: 0;
      font-size: 1.6rem;
      line-height: 2.4rem;
      margin-bottom: 1.6rem;
    }

    .notification {
      background: $notification-bg;
      border-radius: 8px;
      padding: 1.2rem;
      margin-bottom: 2.4rem;
      color: $color-text;

      p {
        font-size: 1.6rem;
        line-height: 2.3rem;
        margin-bottom: 0;
        opacity: 0.7;
      }

      span {
        display: block;
        margin-top: 1.6rem;
        line-height: 2.3rem;
        opacity: 0.7;
      }
    }

    .separator {
      margin-top: 2.4rem;
      margin-bottom: 2.4rem;
      border: 0;
      border-top: 1px solid $color-separator;
    }

    .tshirt {
      display: flex;
      margin-bottom: 1.6rem;

      .iconBox {
        width: 6.2rem;
        height: 6.2rem;
        background: rgba(13, 23, 68, 0.05);
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 2.4rem;

        .icon {
          width: 4rem;
          height: 4.3rem;
          background: url('./assets/small-tshirt.png') no-repeat center;
          background-size: cover;
        }
      }

      .size {
        width: 10.2rem;

        span {
          line-height: 2.4rem;
          font-size: 1.6rem;
        }
      }
    }

    .source {
      display: none;
    }
  }
}

@media only screen and (max-width: $bp-medium) {
  .success {
    flex-direction: column;

    .left {
      margin-top: 7.8rem;
      max-width: none;
      flex-grow: 0;

      .logo {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 10;
        height: 7.8rem;
        padding-left: 15px;
        display: flex;
        align-items: center;
        background: $color-bg;
        transform: none;
      }

      h2 {
        display: block;
        margin-top: 3.5rem;
        width: 44.336rem;
        margin-bottom: 4rem;
      }

      .grace {
        height: 26.5rem;

        h1 {
          font-size: 4.6rem;
          line-height: 5.9rem;
          margin-bottom: 0.5rem;
        }

        h2 {
          font-size: 2.8rem;
          line-height: 3.8rem;
          margin-top: 0;
          width: 100%;
        }

        .labels {
          .label {
            font-size: 1.4rem;
            line-height: 2.1rem;
          }

          .label-icon {
            width: 6.871rem;
            height: 1.783rem;
          }
        }
      }

      .share {
        max-width: 44.336rem;
        margin-bottom: 6rem;
      }

      .source {
        display: none;
      }
    }

    .right {
      align-items: stretch;
      padding-top: 6rem;

      h2 {
        display: none;
      }

      .info {
        margin-bottom: 2.4rem;
      }

      .notification {
        padding: 3.2rem 2.4rem;
        margin-bottom: 3.2rem;

        span {
          margin-top: 2.4rem;
        }
      }

      .separator {
        margin-top: 3rem;
        margin-bottom: 3.2rem;

        &.hidephone {
          display: none;
        }
      }

      .tshirt {
        margin-bottom: 2.4rem;
      }

      .source {
        display: flex;
        margin-top: 4.8rem;
        margin-bottom: 16.3rem;
      }
    }
  }
}

@media only screen and (max-width: $bp-smallest) {
  .success {
    .left {
      h2 {
        width: 26.5rem;
      }

      .grace {
        max-width: 26.5rem;
        border-radius: 0.5rem;
        padding: 2.37rem 2.37rem 2.2rem;
      }

      .share {
        max-width: 26.5rem;
      }
    }
  }
}
