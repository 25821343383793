$bp-largest: 75em; // 1200px
$bp-large: 68.75em; // 1100px
$bp-medium: 56.25em; // 900px

.leaderboard {
  position: relative;
  margin-right: -15px;
  margin-left: -15px;
  border: 1px solid #E0E2E8;
  // filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 9px;
  overflow: hidden;

  &:hover {
    .eventChanger {
      .arrow {
        opacity: .8; 
      }
    }
  }

  .eventChanger {
    z-index: 2;

    .arrow {
      position: absolute;
      top: 0;
      opacity: 0;
      width: 50px;
      height: 50px;
      background-size: contain;
      cursor: pointer;

      transition: 0.2s opacity;

      &.left {
        left: 474px;
        background-image: url('./assets/left.png');
      }

      &.right {
        right: 10px;
        background-image: url('./assets/right.png');
      }
    }
  }
}

.hideDesktop {
  display: none;
}

.leaderboardHeader {
  position: relative;
  display: flex;
  z-index: 2;

  .cell {
    padding: 20px;
    color: #4554D6;
    border-right: 1px solid rgba(13, 23, 68, 0.1) ;
    flex-grow: 0;
    background: rgba(13, 23, 68, 0.03);

    &:last-child {
      border-right: none;
    }

    &.rank {
      min-width: 144px;
      cursor: pointer;
    }

    &.athlete {
      min-width: 320px;
      color: #0D1744;
      opacity: 0.5;
    }

    &.event {
      min-width: 326px;
      flex-grow: 1;
      cursor: pointer;
    }

    &.active {
     background:  rgba(69, 84, 214, 0.15);
    }
  }

}

.leaderboardRow {
  display: flex;
  border-bottom: 1px solid rgba(13, 23, 68, 0.1) ;

  .mobileFlex {
    display: flex;
  }

  .cell {
    padding: 20px;
    border-right: 1px solid rgba(13, 23, 68, 0.1) ;
    flex-grow: 0;

    &:last-child {
      border-right: none;
    }

    &.athleteColumn {
      border-right: 1px solid rgba(13, 23, 68, 0.1) ;
    }
  }

  .rank {
    min-width: 144px;
    font-family: Montserrat,sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
  }

  .athleteColumn {
      min-width: 320px;
      border-right: 1px solid rgba(13, 23, 68, 0.1) ;
   
    .athleteBlock {
      display: flex;

      .singleScore {
        display: none;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        color: #0D1744;
      }

      img {
        margin-right: 10px;
        border-radius: 4px;
        width: 50px;
        height: 50px;
      }

      .athleteName {
        font-family: Montserrat, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        color: #4554D6;
        
        p {
          margin-bottom: 0px; 
        }
      }

      .nationality {
        border-radius: 2px;
        width: 34.5px;
        height: 32px;
      }
    }

    .eventsDropdownSelector {
      display: none;
      background: url('../../screens/LeaderboardScreen/assets/chevron.svg') no-repeat center;
      width: 35px;
      height: 35px;
      &.flip {
        transform: rotate(180deg)
      }
    }
  }

  .events {
    display: flex;
  }

  .eventBlock {
    flex-grow: 1;
    min-width: 326px;

    .eventBlockDetail {
      display: flex;
      align-items: center;
      justify-content:  space-between;

      .mobileFlex {
        display: block;
      }
    }


    .workoutTitle {
      margin-bottom: 0;
      font-family: Montserrat, sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
    }

    .video {
      padding: 8px;
      border-radius: 5px;
      background-size: contain;
    }

    .videoIcon {
      width: 20px;
      height: 20px;
      background: url('../../screens/LeaderboardScreen/assets/play.png') no-repeat center center;
      background-size: contain;
    }
  }
}

.pagination {
  margin: 17px 0 22px 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .arrow {
    background: url('./assets/arrow.svg');
    width: 50px;
    height: 50px;
    cursor: pointer;

    &.left {
      // background: green;
      transform: rotate(180deg);
    }

    &.right {
      // background: red;
    }

    &.off {
      opacity: 0.5;
    }
  }

  .number {
    margin: 0 20px;
  }
}

@media screen and (max-width: $bp-medium) {
  .leaderboard {
    margin-left: 0;
    margin-right: 0;
  }

  .leaderboardHeader {
    display: none;
  }

  .hideDesktop {
    display: block;
  }

  .hideMobile {
    display: none
  }

  .leaderboardRow {
    display: block;

    .mobileFlex {
      align-items: center;
      width: 100%;
    }

    .cell {
      justify-content: center;
      padding: 15px;
      border-right: none;

      &.athleteColumn {
        border-right: none;
      }
    }

    .rank {
      min-width: 45px;
    }

    .athleteColumn {
      min-width: auto;
      flex-grow: 1;
      padding-left: 0px;

      .athleteBlock {
        align-items: center;

        .athleteName {
          width: 80%;
          overflow: hidden;

          p {
            text-overflow: ellipsis;
          }
        }

        .singleScore {
          display: block;
        }

        img {
          width: 35px;
          height: 35px; 
        }
      }
      .rightBlock {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
      }

      .eventsDropdownSelector {
        display: block;
      }
    }

    .showEvents {
      display: block;
    }

    .eventBlock {
      padding: 10px 15px;
      background: #F3F4F9;

      .mobileFlex {
        display: flex;
      }

      &:nth-child(2n+1) {
        background: #fff;
      }

      .workoutTitle {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
      }

      .workoutResults {
        font-family: Montserrat, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
      }
    }
  }
}