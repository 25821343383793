$bp-medium: 56.25em; // 900px

$primary-color: #4554d6;
$error-color: #eb5757;
$color-border: #edf2ff;
$color-text: #0d1744;

.create {
  position: fixed;
  z-index: 90;
  top: 0;
  min-height: 100vh;
  width: 100%;
  background-color: #fff;
  display: flex;

  .gradient {
    max-width: 61.7rem;
    flex-grow: 1;
    overflow: hidden;

    display: flex;
    justify-content: center;
    align-items: center;
    background: radial-gradient(
      73.45% 73.45% at 15% 50%,
      rgba(69, 84, 214, 0.2) 0%,
      rgba(247, 249, 255, 0.05) 100%
    );
  }

  .sign {
    position: relative;
    flex-grow: 1;

    display: flex;
    align-items: center;

    .small-logo {
      display: none;
    }

    h2 {
      font-size: 3.4rem;
      line-height: 4.4rem;
      font-weight: 600;
      margin-bottom: 1.4rem;
    }

    .form {
      .form-group {
        display: flex;
        gap: 2rem;

        & > div {
          flex-grow: 1;
        }

        .birthdayItem {
          div[class^='birthdayItem__control'] {
            border-color: $color-border;
            border-width: 1px;
            border-radius: 0;
            height: 5rem;
          }

          div[class^='birthdayItem__placeholder'] {
            font-size: 1.6rem;
            line-height: 1.9rem;
            color: $color-text;
            opacity: 0.3;
          }
        }
      }

      .gender {
        margin-bottom: 1.6rem;

        .title {
          font-size: 1.2rem;
          line-height: 1.4rem;
          text-transform: uppercase;
          opacity: 0.8;
          margin-bottom: 1.2rem;
          user-select: none;

          span {
            color: $error-color;
            font-size: 1.2rem;
          }
        }

        .radioinput {
          display: none;

          &:checked ~ .radiolabel {
            border: 2px solid $primary-color;
          }
        }

        .radiolabel {
          position: relative;
          width: 100%;
          font-size: 1.6rem;
          line-height: 1.9rem;
          cursor: pointer;
          border: 2px solid $color-border;
          text-align: center;
          padding: 1.6rem;
          height: 5rem;
          margin-bottom: 0;
        }

        .showError {
          height: 2.4rem;
          color: $error-color;
          line-height: 2.4rem;
          font-size: 1.4rem;
        }
      }

      .birthday {
        margin-bottom: 1.6rem;

        .title {
          font-size: 1.2rem;
          line-height: 1.4rem;
          text-transform: uppercase;
          opacity: 0.8;
          margin-bottom: 1.2rem;
          cursor: pointer;
          user-select: none;

          span {
            color: $error-color;
            font-size: 1.2rem;
          }
        }

        .showError {
          display: flex;
          height: 2.4rem;

          & > span {
            flex-grow: 1;
            flex: 1;
            overflow: hidden;
            color: $error-color;
            line-height: 2.4rem;
            font-size: 1.4rem;

            &:not(:last-child) {
              margin-right: 2rem;
            }
          }
        }
      }

      .action {
        .btn {
          font-family: 'Gilroy', sans-serif;
          font-weight: 600;
          font-size: 1.6rem;
          line-height: 1.9rem;
          height: 6.3rem;
          text-transform: uppercase;
          border-radius: 0.8rem;

          &:first-child {
            background: $primary-color;
            border-color: $primary-color;

            &:hover {
              background-color: #0069d9;
              border-color: #0062cc;
            }

            &:disabled {
              color: rgba(255, 255, 255, 0.5);
              background: #4554d6;
              opacity: 0.5;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $bp-medium) {
  .create {
    .gradient {
      display: none;
    }

    .sign {
      align-items: stretch;

      .small-logo {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 10;
        height: 7.8rem;
        padding-left: 15px;
        display: flex;
        align-items: center;
        background-color: #fff;
      }

      .box {
        height: calc(100vh - 7.8rem);
        margin-top: 7.8rem;
        display: flex;
        flex-direction: column;
      }

      h2 {
        font-size: 2.6rem;
        line-height: 2.9rem;
        font-weight: 600;
        margin-top: 3.7rem;
        margin-bottom: 1.6rem;
      }

      .form {
        display: flex;
        flex-direction: column;

        .birthday {
          margin-bottom: 3.6rem;

          .title {
            font-size: 1.6rem;
            opacity: 0.5;
            letter-spacing: 0.05rem;
            font-weight: 600;
            margin-bottom: 1rem;
          }
        }

        .gender {
          margin-bottom: 0;

          .title {
            font-size: 1.6rem;
            opacity: 0.5;
            letter-spacing: 0.05rem;
            font-weight: 600;
            margin-bottom: 1rem;
          }

          .radiolabel {
            border-radius: 0.8rem;
            text-align: left;
          }
        }

        .action {
          margin-top: 0rem;
          margin-bottom: 3.2rem;
        }

        .form-group {
          &.names {
            flex-direction: column;
            gap: 0;
          }
        }
      }
    }
  }
}
