@import 'fonts';

$color-text: #0d1744;

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
}

body {
  font-family: 'Gilroy', sans-serif;
  font-weight: normal;
  font-size: 1.6rem;
  box-sizing: border-box;
  color: $color-text;
}

button:disabled {
  cursor: not-allowed;
}

::-webkit-scrollbar {
  max-width: 0.6rem;
  max-height: 0.6rem;
  background: none;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0.3rem rgba(0, 0, 0, 0.1);
}

::-webkit-scrollbar-corner {
  display: none;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.15);
  border-radius: 1rem;
}
