$bp-largest: 75em; // 1200px
$bp-large: 68.75em; // 1100px
$bp-medium: 56.25em; // 900px

.leaderboard {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 70px;
    padding-bottom: 80px;

    h1 {
      margin-right: 50px;
      font-family: Montserrat, sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 36px;
      line-height: 44px;
    }
  }

  .inputs {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 20px;

    .dropdownsWrapper {
      display: flex;
      
      .dropdownWrapper {
        margin-right: 40px;

        select {
          background: none;
          border: 1px solid rgba(13, 23, 68, 0.1);
          padding: 10px 15px;
          min-width: 160px;

          -webkit-appearance: none; 
          appearance: none;
        }
        .selectChevron {
          position: relative;

          &::after {
            position: absolute;
            right: 10px;
            top: 50%;
            content: "";
            width: 1.8rem;
            height: 0.836rem;
            background: url('./assets/chevron.svg') no-repeat center;
            background-size: contain;

            transform: translateY(-50%);
          }
        }
      }
    }

    .eventWrapper {
      display: none;
      width: 100%;

      select {
        background: none;
        border: 1px solid rgba(13, 23, 68, 0.1);
        padding: 10px 15px;
        min-width: 100%;

        -webkit-appearance: none; 
        appearance: none;
      }

      .selectChevron {
        position: relative;

        &::after {
          position: absolute;
          right: 10px;
          top: 50%;
          content: "";
          width: 1.8rem;
          height: 0.836rem;
          background: url('./assets/chevron.svg') no-repeat center;
          background-size: contain;

          transform: translateY(-50%);
        }
      }
    }

    .inputWrapper {
      position: relative;

      &::after {
        left: 15px;
        top: 50%;
        position: absolute;
        content: "";
        width: 20px;
        height: 20px;
        background: url('./assets/magnifying_glass.svg') no-repeat center;
        background-size: contain;

        transform: translateY(-50%);
      }

      input {
        padding: 10px 15px 10px 50px;
        background: #F7F9FF;
        border: 1px solid rgba(13, 23, 68, 0.1);
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 8px;
        width: 320px;
      }
    }

    label {
      display: block;
      font-family: Montserrat, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
    } 
  }
}

@media screen and (max-width: $bp-medium) {
  .leaderboard {
    // margin-left: 15px;
    // margin-right: 15px;
    .header {
      margin-left: 0px;
      margin-right: 0px;
    }

    .inputs {
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 15px;

      .dropdownsWrapper {
        justify-content: space-between;
        margin-bottom: 15px;
        width: 100%;

        .dropdownWrapper {
          margin-right: 10px;

          select {
            min-width: 156px;
          }

          &:nth-child(2) {
            margin-right: 0;
          }
        }
      }

      .eventWrapper {
        display: block;
        margin-bottom: 15px;
      }

      .inputWrapper {
        margin-bottom: 40px;
        width: 100%;

        input {
          width: 100%;
        }
      }
    }
  }
}