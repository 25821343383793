$bp-medium: 56.25em; // 900px
$bp-small: 37.5em; // 600px
$bp-smallest: 31.25em; // 500px

$color-bg: #fbfcff;

.gradient {
  background: radial-gradient(
      73.45% 73.45% at -10% 30%,
      rgba(69, 84, 214, 0.11) 0%,
      rgba(69, 84, 214, 0) 80%
    ),
    linear-gradient(90deg, #fff 0%, #fff 50%, $color-bg 50%, $color-bg 100%);
  min-height: calc(100vh - 27.4rem);
}

.shirt {
  max-width: 136rem;
  margin: 8.1rem auto 10rem;

  h2 {
    font-size: 4rem;
    font-weight: 600;
    line-height: 6rem;
    margin-bottom: 2rem;
  }
  .left {
    h2 {
      & + p {
        max-width: 52.8rem;
      }
    }

    p {
      max-width: 63rem;
      line-height: 2.4rem;
      margin-bottom: 0;
    }

    h3 {
      font-weight: 600;
      font-size: 2.4rem;
      line-height: 3.2rem;
      margin-top: 2.4rem;
      margin-bottom: 2rem;
    }

    ul {
      &.prizeList {
        margin-left: 1.5rem;
      }

      &.equipments {
        margin-top: 2rem;
        margin-bottom: 4rem;
        list-style: none;

        li {
          position: relative;
          font-size: 1.6rem;
          line-height: 2.4rem;
          max-width: 63rem;
          padding-left: 3.4rem;

          &:not(:last-child) {
            margin-bottom: 1rem;
          }

          &::before {
            content: '';
            width: 2.4rem;
            height: 2.4rem;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            background: url('./assets/check-circle.svg') no-repeat center;
            background-size: contain;
          }
        }
      }
    }

    .marginBottom {
      margin-bottom: 1rem;
    }

    .video {
      position: relative;
      padding-bottom: 56.25%; 
      padding-top: 30px; 
      height: 0; 
      overflow: hidden; 

      .content {
        position: absolute; 
        top: 0; 
        left: 0; 
        width: 100%; 
        height: 100%;
      }

      .play {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 7.8rem;
        height: 7.7rem;
        border: none;
        cursor: pointer;
        background: url('../../assets/img/play.svg') no-repeat center;
        background-size: contain;
        transition: all 0.2s;

        &:focus {
          outline: none;
        }

        &:active {
          transform: translate(-50%, -49%);
        }

        &:hover {
          opacity: 0.7;
        }
      }
    }

    .standards {
      margin-top: 4rem;
      margin-bottom: 4rem;
      p {
        &:not(:last-child) {
          margin-bottom: 3rem;
        }
      }
    }
  }

  .right {
    .tshirt {
      width: 31.848rem;
      height: 33rem;
      background: url('../../assets/img/dual-shirts.png') no-repeat center;
      background-size: contain;
    }

    h3 {
      font-size: 1.6rem;
      line-height: 2.4rem;
      margin: 0;

      &.light {
        margin-top: 4rem;
        margin-bottom: 0.4rem;
        text-transform: uppercase;
        font-weight: 600;
        line-height: 3.6rem;
        letter-spacing: 0.05rem;
        opacity: 0.5;
      }
    }

    .list {
      list-style: none;
      margin-top: 0.8rem;
      margin-bottom: 2.4rem;

      li {
        font-size: 1.6rem;
        line-height: 2.4rem;

        &:not(:last-child) {
          margin-bottom: 0.4rem;
        }
      }
    }
    .pdfs {
      font-size: 1.6rem;
    line-height: 2.4rem;
    }
  }
}

@media only screen and (max-width: $bp-medium) {
  .gradient {
    min-height: calc(100vh - 20.5rem);
    background: none;
  }

  .shirt {
    margin: 3.7rem auto 4rem;

    h2 {
      font-size: 2.6rem;
      margin-top: 6rem;
      margin-bottom: 1.8rem;
      line-height: 2.9rem;
    }

    .right {
      padding-bottom: 3.6rem;
      h2 {
        margin-bottom: 3.3rem;
      }
    }

    .left {
      h3 {
        font-size: 1.8rem;
        line-height: 2.9rem;
        margin-top: 3.2rem;
        margin-bottom: 1.6rem;
      }

      ul {
        &.equipments {
          margin-top: 1.8rem;
          margin-bottom: 3.2rem;

          li {
            &:not(:last-child) {
              margin-bottom: 1.8rem;
            }
          }
        }
      }

      .video {
        text-align: center;
      }

      .standards {
        margin-top: 4.8rem;
        margin-bottom: 3.2rem;
      }
    }
  }
}

@media only screen and (max-width: $bp-small) {
  .shirt {
    margin: 0 auto 4rem;

    .right {
      background-color: $color-bg;

      h2 {
        margin-top: 3.6rem;
      }
    }
  }
}

@media only screen and (max-width: $bp-smallest) {
  .shirt {
    .left {
      .video {
        height: 36rem;
        margin-right: -1.5rem;
        margin-left: -1.5rem;
        background: url('../../assets/img/video_bg_small.png') no-repeat center;
        background-size: cover;
        .big {
          display: none;
        }
      }
    }
  }
}
