$bp-largest: 75em; // 1200px
$bp-small: 37.5em; // 600px

.overflow {
  overflow-y: auto;
}

.box {
  margin-right: auto;
  margin-left: 10rem;
  width: 49rem;
}

@media only screen and (max-width: $bp-largest) {
  .box {
    margin: 0 auto;
  }
}

@media only screen and (max-width: $bp-small) {
  .box {
    width: 100%;
  }
}
