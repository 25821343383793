$bp-medium: 56.25em; // 900px

.division {
  margin-bottom: 4rem;

  .title {
    font-size: 2.4rem;
    line-height: 3.2rem;
    font-weight: 600;
    margin-bottom: 2rem;
  }

  p {
    margin-bottom: 0;
    line-height: 2.4rem;
  }
}

@media only screen and (max-width: $bp-medium) {
  .division {
    .title {
      text-transform: uppercase;
      letter-spacing: 0.05rem;
      font-size: 1.6rem;
      line-height: 1.4rem;
      margin-bottom: 1.6rem;
      opacity: 0.5;
    }
  }
}
