$bp-medium: 56.25em; // 900px

.faq {
  margin-top: 10rem;
  margin-bottom: 10rem;

  h2 {
    font-weight: 600;
    font-size: 4rem;
    line-height: 6rem;
    text-align: center;
    margin-bottom: 6rem;
  }

  h3 {
    font-size: 1.6rem;
    text-align: center;
    margin-bottom: 0;
    font-weight: normal;
  }

  .contact {
    margin-top: 12rem;
    text-align: center;

    h2 {
      margin-bottom: 2rem;
    }

    h3 {
      margin-bottom: 4rem;
      font-size: 1.6rem;
      line-height: 2.4rem;
    }

    .btn {
      font-weight: 600;
      padding: 2rem 6rem;
      border-radius: 0.8rem;
      background: #4554d6;
      border-color: #4554d6;
      text-transform: uppercase;
      font-size: 2rem;
      line-height: 2.4rem;

      &:hover {
        background-color: #0069d9;
        border-color: #0062cc;
      }
    }
  }

  .subtitle {
    font-weight: 600;
  }
}

@media only screen and (max-width: $bp-medium) {
  .faq {
    margin-top: 3.6rem;
    margin-bottom: 6rem;

    h2 {
      font-size: 2.6rem;
      margin-bottom: 2.4rem;
      line-height: 2.9rem;
    }

    .contact {
      margin-top: 6rem;

      h2 {
        margin-bottom: 1.2rem;
      }

      h3 {
        margin-bottom: 2.4rem;
      }

      .btn {
        width: 100%;
        font-size: 1.6rem;
        line-height: 1.9rem;
      }
    }
  }
}
