
$color-bg: #fbfcff;
$color-orange: #f2994a;
$color-btn: #4554d6;
$color-green: #4ec19d;
$color-red: #E64925;


.activeStatus {
  p {
    line-height: 2.4rem;
    margin-bottom: 0.4rem;
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;

    &.soon {
      padding-left: 1.6rem;
      text-transform: capitalize;
      position: relative;

      &::before {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        content: '';
        display: inline-block;
        width: 0.8rem;
        height: 0.8rem;
        background-color: $color-green;
        border-radius: 50%;
      }

      &.closed {
        &::before {
          background-color: $color-red;
        }
      }

      &.upcoming {
        &::before {
          background-color: $color-orange;
        }
      }
    }
  }
}