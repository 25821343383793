$bp-largest: 75em; // 1200px
$bp-large: 68.75em; // 1100px
$bp-medium: 56.25em; // 900px

$color-bg: #fbfcff;
$color-orange: #f2994a;
$color-btn: #4554d6;
$color-green: #4ec19d;
$color-red: #E64925;

.gradient {
  background: radial-gradient(
      73.45% 73.45% at -10% 50%,
      rgba(69, 84, 214, 0.11) 0%,
      rgba(69, 84, 214, 0) 80%
    ),
    linear-gradient(90deg, #fff 0%, #fff 50%, $color-bg 50%, $color-bg 100%);
  min-height: calc(100vh - 27.4rem);
}

.about {
  max-width: 136rem;
  margin: 8.1rem auto 15.1rem;

  h2 {
    font-size: 4rem;
    font-weight: 600;
    line-height: 6rem;
    margin-bottom: 2rem;
  }

  .left {
    p {
      max-width: 63rem;
      line-height: 2.4rem;
      margin-bottom: 0;

      &:not(:last-child) {
        margin-bottom: 3rem;
      }
    }
  }

  .right {
    .content {
      padding-left: 2.5rem;

      h2 {
        position: relative;
        padding-left: 5.2rem;
        font-size: 3rem;

        &::before {
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          content: '';
          display: inline-block;
          width: 3.2rem;
          height: 3.2rem;
          background: url('./assets/b_logo.png') no-repeat center;
          background-size: contain;
        }
      }

      p {
        line-height: 2.4rem;
        margin-bottom: 0.4rem;

        &.soon {
          padding-left: 1.6rem;
          text-transform: capitalize;
          position: relative;

          &::before {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            content: '';
            display: inline-block;
            width: 0.8rem;
            height: 0.8rem;
            background-color: $color-green;
            border-radius: 50%;
          }

          &.closed {
            &::before {
              background-color: $color-red;
            }
          }
        }
      }

      .action {
        display: flex;
        margin-bottom: 4rem;
        margin-top: 4rem;

        .btn {
          font-family: 'Gilroy', sans-serif;
          font-weight: 600;
          font-size: 2rem;
          line-height: 2.4rem;
          text-transform: uppercase;
          padding: 2rem 5rem;
          height: 6.4rem;
          border-radius: 0.8rem;

          &:first-child {
            background: $color-btn;
            border-color: $color-btn;

            &:hover {
              background-color: #0069d9;
              border-color: #0062cc;
            }
          }

          &:last-child {
            // border-color: $color-btn;
            // color: $color-btn;

            &:hover {
              // color: #fff;
              // background: $color-btn;
            }
          }

          &:not(:last-child) {
            margin-right: 1.6rem;
          }
        }
      }

      .logos {
        // display: flex;

        .charity {
          margin-right: 12rem;
        }
      }
    }
  }
}

@media only screen and (max-width: $bp-largest) {
  .about {
    .right {
      .content {
        padding-left: 0;
      }
    }
  }
}

@media only screen and (max-width: $bp-large) {
  .about {
    h2 {
      font-size: 2.6rem;
      margin-bottom: 0;
    }

    .right {
      .content {
        .action {
          flex-wrap: wrap;
          margin-top: 2.4rem;
          margin-bottom: 3.2rem;

          .btn {
            font-size: 1.6rem;
            width: 100%;

            &:not(:last-child) {
              margin-right: 0;
              margin-bottom: 0.8rem;
            }
          }
        }

        .logos {
          justify-content: space-between;

          .charity {
            margin-right: 0;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $bp-medium) {
  .gradient {
    min-height: calc(100vh - 20.5rem);
    background: none;
  }

  .about {
    margin: 2.5rem auto 0;
    margin-bottom: 0;
    .right {
      background: $color-bg;

      .content {
        margin-top: 5rem;
        margin-bottom: 6rem;
      }
    }

    .left {
      padding-bottom: 6rem;
      p {
        margin-bottom: 0;

        &:not(:last-child) {
          margin-bottom: 1.6rem;
        }
      }
    }
  }
}
