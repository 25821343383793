.box {
  h3 {
    &.top,
    &.bottom {
      opacity: 0.5;
      font-size: 1.6rem;
      font-weight: 600;
      line-height: 2.4rem;
    }

    &.top {
      margin-bottom: 0.8rem;
      text-transform: uppercase;
      letter-spacing: 0.05rem;
    }

    &.bottom {
      text-transform: capitalize;
      margin-top: 0.8rem;
      margin-bottom: 0;
    }
  }

  .logo {
    width: 15.8rem;
    height: 16.9rem;
    background: url('./assets/btwb_logo_black.png') no-repeat center;
    background-size: contain;
  }

  .charity {
    margin: 2rem 0;
    width: 21.5rem;
    height: 8rem;
    background: url('./assets/gary-sinise-foundation.png') no-repeat;
    background-size: contain;
  }
}
