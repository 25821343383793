$bp-medium: 56.25em; // 900px

$color-border: #edf2ff;
$primary-color: #4554d6;
$color-text: #0d1744;
$color-separator: #edf2ff;

.division {
  position: fixed;
  z-index: 90;
  top: 0;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  display: flex;
  color: $color-text;

  .gradient {
    max-width: 61.7rem;
    flex-grow: 1;
    overflow: hidden;

    display: flex;
    justify-content: center;
    align-items: center;
    background: radial-gradient(
      73.45% 73.45% at 15% 50%,
      rgba(69, 84, 214, 0.2) 0%,
      rgba(247, 249, 255, 0.05) 100%
    );
  }

  .choice {
    position: relative;
    flex-grow: 1;

    display: flex;
    align-items: center;

    .small-logo {
      display: none;
    }

    h2 {
      font-size: 3.4rem;
      line-height: 4.4rem;
      font-weight: 600;
      margin-bottom: 2rem;
    }

    .tip {
      font-size: 1.6rem;
      line-height: 2.4rem;
      margin-bottom: 1rem;
    }

    .about {
      // font-family: 'SF Pro Display', sans-serif;
      font-weight: normal;
      color: $primary-color;
      font-size: 1.4rem;
      line-height: 1.7rem;
      cursor: pointer;
      transition: all 0.2s ease;

      &:hover {
        opacity: 0.8;
      }
    }

    .separator {
      margin-top: 2.4rem;
      margin-bottom: 2.4rem;
      border: 0;
      border-top: 1px solid $color-separator;
    }

    .form {
      .divisions {
        margin-bottom: 4rem;

        .item {
          background-color: #fff;
          border: 2px solid $color-border;
          width: 100%;
          padding: 1.6rem;
          font-size: 1.6rem;
          line-height: 1.9rem;
          height: 5rem;

          &:not(:last-child) {
            margin-bottom: 1.6rem;
          }

          &.active {
            border: 2px solid $primary-color;
          }

          &:focus {
            outline: none;
          }
        }
      }

      .formcheck {
        display: flex;
        margin-bottom: 3rem;

        .custom {
          input {
            display: none;

            &:checked + label {
              background: url('./assets/checkmark.svg') no-repeat center
                $primary-color;
            }
          }

          label {
            width: 1.6rem;
            height: 1.6rem;
            border: 1px solid $primary-color;
            border-radius: 2px;
            cursor: pointer;
            margin-bottom: 0;
          }
        }

        p {
          margin-left: 0.8rem;
          margin-bottom: 0;
          font-size: 1.4rem;
          line-height: 1.8rem;
          color: $color-text;

          a {
            text-decoration: none;
            color: $primary-color;
          }
        }
      }

      .btn {
        font-family: 'Gilroy', sans-serif;
        font-weight: 600;
        font-size: 1.6rem;
        line-height: 1.9rem;
        height: 6.3rem;
        border-radius: 0.8rem;
        background: $primary-color;
        border-color: $primary-color;

        &:hover {
          background-color: #0069d9;
          border-color: #0062cc;
        }

        &:disabled {
          color: rgba(255, 255, 255, 0.5);
          background: #4554d6;
          opacity: 0.5;
        }

        &:focus {
          outline: none;
        }
      }
    }
  }
}

@media only screen and (max-width: $bp-medium) {
  .division {
    .gradient {
      display: none;
    }

    .choice {
      .small-logo {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 10;
        height: 7.8rem;
        padding-left: 15px;
        display: flex;
        align-items: center;
        background-color: #fff;
      }

      .box {
        height: 100vh;
        display: flex;
        flex-direction: column;
      }

      h2 {
        font-size: 2.6rem;
        line-height: 2.9rem;
        font-weight: 600;
        margin-top: 11.5rem;
        margin-bottom: 1.6rem;
      }

      .tip {
        margin-bottom: 1.6rem;
      }

      .form {
        display: flex;
        flex-direction: column;
        height: 100%;

        .divisions {
          margin-bottom: auto;

          .item {
            border-radius: 0.8rem;
            font-size: 1.4rem;
            line-height: 1.6rem;
          }
        }

        .formcheck {
          margin-top: 1.6rem;
          margin-bottom: 2.4rem;
        }

        .btn {
          margin-bottom: 3.2rem;
          text-transform: uppercase;
        }
      }
    }
  }
}
