$bp-medium: 56.25em; // 900px
$bp-small: 37.5em; // 600px
$bp-smallest: 31.25em; // 500px

$footer-color: #0d1744;
$social-color: #c9c9dc;

.footer {
  background-color: $footer-color;
  min-height: 17.4rem;
  color: #fff;
  display: flex;
  align-items: center;

  .box {
    max-width: 136rem;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 2.8rem;

    .social {
      font-size: 1.6rem;
      display: flex;
      align-items: center;
      // gap: 2rem;

      & > a {
        padding-right: 2rem;
        color: $social-color;
        transition: all 0.3s ease;

        &:hover {
          color: #fff;
        }
      }
    }
  }
}

@media only screen and (max-width: $bp-medium) {
  .footer {
    min-height: 12.7rem;

    .box {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .social {
        & > a {
          padding-right: 0;
          padding-left: 2rem;
        }
      }
    }
  }
}
