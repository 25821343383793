$color-bg: #f7f9ff;
$color-title: #0d1744;
$color-title-active: #4554d6;

$bp-medium: 56.25em; // 900px

.question {
  background: $color-bg;
  margin-bottom: 1.6rem;
  padding: 2rem;
  position: relative;

  &.active {
    .title {
      color: $color-title-active;
      margin-bottom: 2rem;
    }

    .chevron {
      transform: rotateX(180deg);
    }

    .answer {
      display: block;
    }
  }

  .title {
    color: $color-title;
    font-size: 1.6rem;
    line-height: 2.4rem;
    cursor: pointer;
  }

  .answer {
    display: none;
    font-size: 1.6rem;
    line-height: 2.4rem;
    max-width: 68rem;

    .inner {
      margin-bottom: 3rem;
    }
  }

  .chevron {
    position: absolute;
    top: 2.8rem;
    right: 2rem;
    cursor: pointer;
    border: 0;
    width: 1.8rem;
    height: 0.836rem;
    background: url('./assets/chevron.svg') no-repeat center;
    background-size: contain;
    transition: all 0.3s ease;

    &:focus {
      outline: none;
    }

    &:hover {
      opacity: 0.7;
    }
  }

  .subtitle {
    font-weight: 600;
    font-size: 1.75rem;
  }
}

@media only screen and (max-width: $bp-medium) {
  .question {
    .title {
      display: inline-block;
      max-width: 21.7rem;
      line-height: 2.2rem;
    }

    .answer {
      max-width: 25.1rem;
    }
  }
}
