$bp-medium: 56.25em; // 900px

.divisions {
  max-width: 136rem;
  margin: 8.1rem auto 10rem;

  h2 {
    font-weight: 600;
    font-size: 4rem;
    line-height: 6rem;
    margin-bottom: 2rem;
  }

  .left {
    div[class^='Division_division'] {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

@media only screen and (max-width: $bp-medium) {
  .divisions {
    margin: 3.6rem auto 6rem;

    h2 {
      font-size: 2.6rem;
      line-height: 2.9rem;
      margin-bottom: 3.2rem;
    }
  }
}
